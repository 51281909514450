<template>
  <b-container fluid>
    <bo-page-title />
    <section id="career">
      <bo-card title="Job Applicants" use-table v-if="isList">
        <b-card-body>
          <b-row>
            <b-col md="2">
                <div class="input-group">
                    <datepicker  input-class="form-control transparent" :value="filter.startDate" placeholder="Start Date" @input="filter.startDate = fixDate($event),doFilterDate()"></datepicker>
                    <div class="input-group-append calendar-group">
                        <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                    </div>
                </div>
            </b-col>
            
            <b-col md="2">
                <div class="input-group">
                    <datepicker input-class="form-control transparent" :value="filter.endDate" placeholder="End Date"  @input="filter.endDate = fixDate($event),doFilterDate()" :disabledDates="{ to: new Date(filter.startDate) }"></datepicker>
                    <div class="input-group-append calendar-group">
                        <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                    </div>
                </div>
            </b-col>
            <b-col md="2">
              <v-select :options="mrPos" v-model="filter.pos" @input="doFilter()" :reduce="v => v.value" />
            </b-col>
            <b-col md>
            </b-col>
            <b-col md="auto d-flex">
              <template v-if="(data.data||[]).length">
                <b-dropdown right class="mr-2" variant="success" v-if="moduleRole('export')" dropbottom>
                  <template #button-content>
                    <i class="ti-upload mr-2"></i> Ekspor Data 
                  </template>
                  <b-dd-item-button @click="download('', {exptype: 'xlsx', tab: 'applicants'})">Sebagai .xls</b-dd-item-button>
                  <b-dd-item-button @click="download('', {exptype: 'pdf', tab: 'applicants'})">Sebagai .pdf</b-dd-item-button>
                </b-dropdown>
              </template>

              <b-form @submit.prevent="doFilter()" class="d-flex">
                <b-input-group>
                  <b-form-input v-model="filter.search" v-on:keyup.13="$emit('search')" placeholder="Type keyword then enter..."></b-form-input>
                  <b-input-group-append>
                    <b-button @click="doFilter()" variant="success"><i class="fas fa-search"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-button
                      class="ml-1 d-inline-flex align-items-center"
                      variant="outline-success"
                      @click="doReset()"
                  >Reset</b-button>
              </b-form>
            </b-col>
          </b-row>
        </b-card-body>
        <b-table
          class="mb-custom-7"
          :fields="applicantFields"
          :items="(data.data||[])"
          show-empty
        >
          <template #cell(num)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}                
          </template>
          <template #cell(name)="data">
            <div>{{ data.item.sc_fullname }}</div>
            <small class="d-inline-block">
              <i class="ti-email mr-1"></i>
              <span>{{ data.item.sc_email }}</span>
            </small><br/>
            <small class="d-inline-block">
              <i class="icon-phone mr-1"></i>
              <span>{{ data.item.sc_phone }}</span>
            </small>
          </template>
          <template #cell(applyFor)="data">
            <div>{{ data.item.mc_position }}</div>
            <small>in <strong class="text-primary">{{data.item.mo_city + ' - ' + data.item.mof_name }}</strong></small>
          </template>
          <template #cell(cv)="data">
            <div class="wrap_pdf"><a :href="uploaderAssets(data.item.sc_cv)" target="_blank"><img 
            src="/assets/images/pdf.png"> Preview CV ({{data.item.sc_cv_size.bytesToSize()}})</a></div>
          </template>
          <template #cell(date)="data">
            <div>{{ data.item.sc_submit_date | moment('LLL') }}</div>          
          </template>
          <template #cell(status)="data">
            <b>{{ data.item.sc_is_active === 'D' ? 'Unread' : 'Read'}}</b>          
          </template>
          <template #cell(actions)="data">
            <b-button
              variant="outline-info"
              class="btn-icon"
              v-b-tooltip.hover="'View Details'"
              :to="{ name: routeName, params: { pageSlug: data.item.sc_id } }"
            ><i class="icon-eye"></i></b-button>

            <a :href="uploaderAssets(data.item.sc_cv)" download target="_blank">
            <b-button
              variant="outline-info"
              class="ml-2 btn-icon"
              v-b-tooltip.hover="'Download'"
            ><i class="ti-download"></i></b-button>
            </a>

          </template>
          <template #empty>
              <div class="text-center">
              <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
              <h4 align="center"><span v-if="Object.keys(filter).length">There is No Data</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
              </div>
          </template>
        </b-table>
        <template #footer>
            <b-pagination
                v-if="data.total > data.per_page"
                class="mb-0 justify-content-end"
                v-model="pageNo"
                :per-page="data.per_page"
                :total-rows="data.total"
            />
        </template>
      </bo-card>




      <bo-card title="Applicant Details" v-else>
        <b-row class="gutter-2">
          <b-col md="3">
            <div class="contact-info">
              <strong class="contact-info__title">Applicant's Name</strong>
              <span class="contact-info__main">{{row.sc_fullname}}</span>
            </div>
          </b-col>
          <b-col md="3">
            <div class="contact-info">
              <strong class="contact-info__title">Contacts</strong>
              <a :href="'mailto:'+row.sc_email" class="contact-info__main">
                <i class="ti-email"></i> {{row.sc_email}}</a>
              <a :href="'tel:'+row.sc_phone" class="contact-info__main">
                <i class="icon-phone"></i> {{row.sc_phone}}
              </a>
            </div>
          </b-col>
          <div class="w-100"></div>
          <b-col md="3">
            <div class="contact-info">
              <strong class="contact-info__title">Apply For</strong>
              <span class="contact-info__main">{{row.mc_position}}</span>
              <div><small>in <strong class="text-primary">{{row.mo_city + ' - ' +row.mof_name}}</strong></small></div>
            </div>
          </b-col>
          <b-col md="3">
            <div class="contact-info">
              <strong class="contact-info__title">CV</strong>
              <div class="wrap_pdf"><a :href="uploaderAssets(row.sc_cv)" target="_blank"><img src="/assets/images/pdf.png"> Preview CV ({{row.sc_cv_size.bytesToSize()}})</a></div>
            </div>
          </b-col>

          <b-col md="8">
            <div class="contact-info">
              <strong class="contact-info__title">Introduction</strong>
              <span class="contact-info__main">{{row.sc_introduction}}</span>
            </div>
          </b-col>
        </b-row>
        <template #footer="">
          <div class="text-right">
            <b-button :to="{name: routeName}" variant="info" class="btn-rounded">Back to Applicant List</b-button>
          </div>
        </template>
      </bo-card>
    </section>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import $ from 'jquery'
import _ from 'lodash'
import Datepicker from 'vuejs-datepicker'
// import Gen from '@/libs/Gen.js'
// import $ from 'jquery'
// import _ from 'lodash'
const moment = require('moment')

export default {
  extends: GlobalVue, 
  components: {
    Datepicker
  },
  data() {
    return {
      currentPage: 1,

      applicantFields: [
        { key: 'num', label: '#' },
        { key: 'name', label: 'Applicant\'s Name' },
        'applyFor',
        { key: 'cv', label: 'CV' }, 
        'date',
        'status',
        'actions'
      ],
      mrPos: [],
      val : 'all',
      seoRow: {},
      heroEditorOpen: false,
      isFound : true
    }
  },
  computed:{
    pageNo: {
      // getter
      get:function() {
        return this.filter.page||this.$route.query.page||1
      },
      // setter
      set:function(newValue) {
        this.$set(this.filter, "shown", 50)
        this.$set(this.filter, "page", newValue)
        this.$router.push({
          name:this.modulePage,
          query:_.clone(this.filter)
        }).catch(()=>{})
      }
    },

    perPage:{
      // getter
      get:function() {
        return this.$route.query.shown||this.filter.shown||50
      },
      // setter
      set:function(newValue) {
        this.pageNo=1
        this.$router.push({
          name:this.modulePage,
          query:{shown:newValue}
        }).catch(()=>{})
      }
    },
  },
  methods: {
    doFilterApplicants(){
      this.filter.pos = this.val
      this.doFilter()
    },
    apiGet(params = {}){
      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")
      if(params.slugs) slugs = params.slugs
      Gen.apiRest(
        "/get/"+this.modulePage+
        slugs, 
        {
          params: Object.assign({}, this.apiParams, params.query||{})
        }
      ).then(res=>{
        this.loadingOverlay = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        if(!this.isFound){
          this.filter['pos'] = 'all'
        }
        if(!this.isList){
          this.setFocusForm()
        }
        setTimeout(()=>{
          $('.label_error').hide()
        },1000)
        if(params.callback) params.callback()
      }).catch(()=>{
        this.loadingOverlay = false
      })
    },
    doReset(){
      const filterKeys = Object.keys(this.filter)
      const querys = _.assign(_.omit(this.$route.query||{}, filterKeys), {page:1})

      this.filter={}
      this.$router.push({name:this.$route.name,query:querys}).catch(()=>{})
      this.filter['pos'] = 'all'
    },
    fixDate(event){
        return moment(event).format('YYYY-MM-DD')
    },
    doFilterDate(){
        if(this.filter.startDate && this.filter.endDate){
            this.doFilter()
        }
    }
  },
	mounted() {
		this.apiGet()
    if(!this.$route.query.pos){
      this.filter['pos'] = 'all'
    } 
  },
  watch:{
      $route(){
        this.apiGet()
      }
      // '$route.params.pageSlug':function(){
      //   this.$set(this,'filter',{})
      // }
  }
}
</script>